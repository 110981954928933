<template>
  <v-container
    id="users"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="6"
      >
        <base-material-statistic
          icon="mdi-chart-areaspline-variant"
          color="red"
          title="Key Registers"
          :url="registers"
          keyword="chartsData"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="6"
      >
        <base-material-statistic
          icon="mdi-chart-areaspline-variant"
          color="yellow"
          title="Keys Usage"
          :url="usage"
          keyword="chartsData"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <base-material-log-table
          color="indigo"
          icon="mdi-update"
          title="Keys Log"
          :url="logUrl"
          :date-picker="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      registers: '/v1/api/stats/keys/reg', // /:start/:end
      usage: '/v1/api/stats/keys/auth', // /:start/:end
      logUrl: '/v1/api/stats/keys/logs', // /:start/:end
    }),
  }
</script>
